import React from 'react';
import Script from 'next/script';

type GoogleTagManagerProps = {
    googleTagManagerId: string;
};

export const GoogleTagManager = ({ googleTagManagerId }: GoogleTagManagerProps) => {
    return (
        <>
            <noscript
                dangerouslySetInnerHTML={{
                    __html: `<iframe src="https://tracking.kompan.com/ns.html?id=${googleTagManagerId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
                }}
            ></noscript>
        </>
    );
};
