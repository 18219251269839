import { Text } from '$shared/components';
import styled from '@emotion/styled';
import { ifProp, switchProp } from 'styled-tools';
import { theme } from '~/theme';

const FormRowGap = theme.spaces[6];
const FormRowSpace = theme.spaces[4];
const FormRowSpaceLg = theme.spaces[6];
const FormRowSpaceXl = theme.spaces[7];

export const StyledFormElement = styled.div<{ split?: boolean; spaceSize?: 'lg' | 'xl' }>(
    {
        ':not(:first-of-type)': {
            paddingTop: FormRowSpace,
        },
    },
    switchProp('spaceSize', {
        lg: {
            ':not(:first-of-type)': {
                paddingTop: FormRowSpaceLg,
            },
        },
        xl: {
            ':not(:first-of-type)': {
                paddingTop: FormRowSpaceXl,
            },
        },
    }),
    ifProp('split', {
        display: 'flex',
        flexFlow: 'wrap row',
        columnGap: FormRowGap,
        rowGap: FormRowGap,
        paddingTop: FormRowSpace,
    }),
);

export const StyledRadioGroupTitle = styled(Text)({
    flex: '1 1 100%',
    paddingBottom: FormRowSpace,
});

export const StyledAddresGroup = styled.div({
    display: 'grid',
    gridTemplateColumns: '30% 1fr',
    gap: FormRowGap,
    paddingTop: FormRowSpace,
});
